<script setup>
import { onMounted, ref } from 'vue';
import loadShopJs from '@utils/shop';

const loading = ref(false);

onMounted(async() => {
    loading.value = true;

    await loadShopJs();
    await Ecwid.init();

    loading.value = false;
});
</script>

<template>
    <div class="after:border-brandPurple" :class="{ 'loading': loading }">
        <div class="ec-cart-widget text-brandPurple"></div>
    </div>
</template>

<style>
html#ecwid_html body#ecwid_body .ec-minicart__icon .icon-default circle[fill].cart-not-empty {
    fill: theme('colors.brandLavender') !important;
}

html#ecwid_html body#ecwid_body .ec-minicart--m .ec-minicart__counter {
    color: theme('colors.gray.900') !important;
}

html#ecwid_html body#ecwid_body .ec-minicart--m .ec-minicart__counter::after {
    background-color: theme('colors.brandPink') !important;
}

html#ecwid_html body#ecwid_body .ec-minicart__icon .icon-default path[stroke] {
    stroke: theme('colors.brandPurple') !important;
}

html#ecwid_html body#ecwid_body .ec-minicart__icon {
    color: theme('colors.brandPurple') !important;
}
</style>
