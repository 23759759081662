// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';
import '@splidejs/vue-splide/css';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp, ref, watchEffect } from 'vue';

// Import all globally-available components here. Be responsible please.
import { useScrollLock } from '@vueuse/core';
import { vOnClickOutside } from '@vueuse/components';

import {
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    TransitionRoot,
    TransitionChild,
} from '@headlessui/vue';

import SplideCarousel from '@components/SplideCarousel.vue';
import { SplideTrack, SplideSlide } from '@splidejs/vue-splide';

import HeroImageScroller from '@components/HeroImageScroller.vue';
import LazyHeader from '@components/LazyHeader.vue';

import {
    ShopCategoryNav, ShopProducts, ShopCart, ShopSearch,
} from '@components/shop';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDialog: Dialog,
        HuiDialogPanel: DialogPanel,
        HuiDialogTitle: DialogTitle,
        HuiDialogDescription: DialogDescription,
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        HuiTransitionRoot: TransitionRoot,
        HuiTransitionChild: TransitionChild,
        LazyHeader,
        SplideCarousel,
        SplideCarouselTrack: SplideTrack,
        SplideCarouselSlide: SplideSlide,
        HeroImageScroller,
        ShopCategoryNav,
        ShopProducts,
        ShopCart,
        ShopSearch,
    },

    directives: {
        onClickOutside: vOnClickOutside,
    },

    setup() {
        const navActive = ref(false);

        const closeNav = () => {
            console.log('test');
            navActive.value = false;
        };

        const scrollLocked = useScrollLock(document.body);

        watchEffect(() => {
            if (navActive.value == false) {
                scrollLocked.value = false;
                return;
            }

            scrollLocked.value = true;
        });

        return {
            navActive,
            closeNav,
        };
    },
}).mount('#app');
