export default function loadShopJs() {
    return new Promise((resolve) => {
        if (typeof Ecwid !== 'undefined') {
            resolve();
        }

        const script = document.createElement('script');
        script.setAttribute('src', 'https://app.business.shop/script.js?91409060&data_platform=code&data_date=2024-02-21');
        script.setAttribute('type', 'text/javascript');
        document.body.append(script);

        script.onload = () => {
            resolve();
        };
    });
}
