<script setup>
import { onMounted } from 'vue';
import loadShopJs from '@utils/shop';

const props = defineProps({
    categoryId: {
        type: String,
        default: '',
    },
});

onMounted(async() => {
    await loadShopJs();

    if (props.categoryId !== '') {
        xProductBrowser('categoriesPerRow=3', 'views=grid(20,3) list(60) table(60)', 'categoryView=grid', 'searchView=list', 'id=product-list', `defaultCategoryId=${props.categoryId}`);
        return;
    }

    xProductBrowser('categoriesPerRow=3', 'views=grid(20,3) list(60) table(60)', 'categoryView=grid', 'searchView=list', 'id=product-list');
});
</script>

<template>
    <div id="product-list"></div>
</template>

<style>
html#ecwid_html body#ecwid_body .ec-size.ec-size--l .ec-store .ec-page-title .ec-header-h1 {
    display: none;
}
</style>
