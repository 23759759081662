<script setup>
import { onMounted } from 'vue';
import loadShopJs from '@utils/shop';

const props = defineProps({
    categoryId: {
        type: String,
        default: '',
    },
});

onMounted(async() => {
    await loadShopJs();

    xSearch('id=product-search');
});
</script>

<template>
    <div id="product-search"></div>
</template>

<style>
html#ecwid_html body#ecwid_body .ec-size .ec-store .search--has-results {
    display: none;
}
</style>
