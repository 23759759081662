<script setup>
import {
    nextTick, onMounted, ref, watchEffect,
} from 'vue';

import { useWindowSize } from '@vueuse/core';

const { width: windowWidth, height: windowHeight } = useWindowSize();

const props = defineProps({
    images: {
        type: Array,
        required: true,
    },
});

const getRandBetween = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

const images = ref([]);

props.images.forEach((imageData, index, array) => {
    const image = {
        ...imageData,
        xPos: getRandBetween(0, windowWidth.value / 10),
        yPos: getRandBetween(10, 15) * (index + 1) * 1.6,
        animationDuration: Math.floor(
            (3 + index + getRandBetween(1, array.length) / 100) * 10000,
        ),
    };

    images.value.push(image);
});

onMounted(async() => {
    await nextTick();

    const imageEls = document.querySelectorAll('.float-up');

    imageEls.forEach((imageEl) => {
        imageEl.addEventListener('animationend', (event) => {
            const $el = event.target;

            const id = parseInt($el.dataset.id);

            const image = images.value.find((image) => {
                return id === image.id;
            });

            if (!image) {
                return;
            }

            image.yPos = 100;
            image.animationDuration = getRandBetween(70000, 80000);

            // Restart the animation with the updated values
            // https://css-tricks.com/restart-css-animation/
            $el.classList.remove('float-up');
            void $el.offsetWidth;
            $el.classList.add('float-up');
        });
    });
});
</script>

<template>
    <div
        v-for="(image, index) in images"
        ref="imageEls"
        :key="image.id"
        class="absolute top-0 w-2/5 aspect aspect-4x3 shadow-xl float-up"
        :class="
            index % 2 ? 'left-[--starting-x-pos]' : 'right-[--starting-x-pos]'
        "
        :style="{
            '--starting-x-pos': `${image.xPos}px`,
            '--starting-y-pos': `${image.yPos}vh`,
            '--animation-duration': `${image.animationDuration}ms`,
        }"
        :data-id="image.id"
    >
        <img
            class="absolute top-0 left-0 w-full h-full object-cover"
            :src="image.src"
            :alt="image.alt"
            :style="{
                objectPosition: `${image.focalPoint.x * 100}% ${
                    image.focalPoint.y * 100
                }%`,
            }"
        >
    </div>
</template>

<style>
:root {
    --starting-x-pos: 0;
    --starting-y-pos: 100vh;
    --animation-duration: 30000ms;
}

.float-up {
    animation: float-up linear var(--animation-duration);
}

@keyframes float-up {
    0% {
        transform: translateY(var(--starting-y-pos));
    }

    100% {
        transform: translateY(-50vh);
    }
}
</style>
