<template>
    <Splide ref="splide" @splide:moved="onMoved">
        <slot :index="index" :next-slide="nextSlide" :prev-slide="prevSlide" :go-to-slide="goToSlide"></slot>
    </Splide>
</template>

<script>
import {
    ref, onMounted,
} from 'vue';
import { Splide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

export default {
    name: 'SplideCarousel',

    components: {
        Splide,
    },

    setup() {
        const splide = ref(null);
        const index = ref(0);

        const prevSlide = () => {
            splide.value.go('<');
        };

        const nextSlide = () => {
            splide.value.go('>');
        };

        const goToSlide = (index) => {
            splide.value.go(index);
        };

        const onMoved = () => {
            index.value = splide.value.splide.index;
        };

        return {
            splide,
            index,
            onMoved,
            prevSlide,
            nextSlide,
            goToSlide,
        };
    },
};
</script>
