<script setup>
import { onMounted } from 'vue';
import loadShopJs from '@utils/shop';

const props = defineProps({
    categoryId: {
        type: String,
        default: '',
    },
});

onMounted(async() => {
    await loadShopJs();

    xCategoriesV2('id=categories');
});
</script>

<template>
    <div id="categories"></div>
</template>
